.inner {
  display: grid;
  grid-template-columns: minmax(var(--size-20), 1fr) repeat(3, 4fr) 2fr;
  grid-template-rows: auto;
  z-index: 0;
  position: relative;

  @media (--viewport-md) {
    grid-template-columns: repeat(4, 2fr) 1fr;
  }

  & > .contactLayout {
    grid-column: 2 / span 4;
    z-index: 1;
    margin-top: calc(-1 * var(--size-60));

    @media (--viewport-md) {
      grid-column: 2 / span 4;
      margin-top: 0;
    }
  }

  & > .imageLayout {
    grid-column: 1 / span 2;
    grid-row: 1;

    @media (--viewport-md) {
      margin-left: var(--size-80);
      margin-right: calc(-1 * var(--size-80));
      margin-top: calc(-1 * var(--size-80));
      grid-column: 1;
    }
  }

  & > .pointerLayout {
    display: none;

    @media (--viewport-lg) {
      position: absolute;
      display: block;
      right: calc(-1 * var(--size-70));
      bottom: calc(-1 * var(--size-120));
      z-index: 1;
    }
  }
}

.componentContact {
  background-color: var(--color-yellow);
  color: var(--color-black);
  padding: var(--size-20);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    'title  title   title'
    'text   text    text'
    'button button  button'
    'link   link    link';

  @media (--viewport-md) {
    padding: var(--size-80) var(--size-50) var(--size-40) var(--size-50);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'title title title'
      'text text text'
      'button link link';
  }

  & > .heading {
    grid-area: title;
    margin-bottom: var(--size-20);
  }

  & > .text {
    grid-area: text;
    margin-bottom: var(--size-30);
  }

  & > .buttonLayout {
    grid-area: button;
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: 0;
    }
  }

  & > .linkLayout {
    grid-area: link;
    align-self: center;
    justify-self: start;

    @media (--viewport-md) {
      margin-left: var(--size-30);
    }
  }
}

.componentContactWithGlasses {
  position: relative;
  z-index: 0;

  & > .bril {
    position: absolute;
    inset: 0;
  }
}

.heading {
  font-size: var(--font-size-32-45);
  font-weight: var(--font-weight-base-500);
  line-height: var(--line-height-compact-md);
}
