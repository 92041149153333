.component {
  display: flex;

  & > .layout {
    width: 100%;
    height: 100%;
  }
}

.layout {
  padding: 0 var(--size-20);
  position: relative;
}

.carousel {
  & > .item {
    height: 500px;
    width: calc(100vw / 2.3) !important;
    min-width: calc(100vw / 2.3) !important;

    @media (--viewport-sm) {
      width: calc(100vw / 2.3) !important;
      min-width: calc(100vw / 2.3) !important;
    }
    @media (--viewport-xxl) {
      width: calc(100vw / 2.3) !important;
      min-width: calc(100vw / 2.3) !important;
    }
    @media (--viewport-xxxl) {
      width: calc(100vw / 3.4) !important;
      min-width: calc(100vw / 3.4) !important;
    }
  }
}

.componentImage {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
  }

  & > .image {
    width: 100%;
    height: 100%;
  }
}

.item {
  display: grid;
  grid-template-areas:
    '... ...    ...   ...'
    '... client ...   ...'
    '... link  icon  ...'
    '... ...    ...   ...';
  grid-template-rows: 1fr auto auto var(--size-20);
  grid-template-columns: var(--size-20) 1fr auto var(--size-20);
  padding-top: var(--size-10);
  position: relative;
  z-index: 0;

  & > .imageLayout {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    z-index: -1;
  }

  & > .client {
    grid-area: client;
  }

  & > .link {
    grid-area: link;
    position: static;
  }

  & > .buttonLayout {
    grid-area: icon;
    align-self: end;
  }
}

.image {
  object-fit: cover;
  object-position: center;
}

.client {
  font-size: var(--font-size-16);
  padding-bottom: 15px;

  @media (--viewport-md) {
    font-size: var(--font-size-20);
    padding-bottom: 10px;
  }

  @media (--viewport-lg) {
    font-size: var(--font-size-24);
  }
}

.link {
  font-size: var(--font-size-60);
  font-weight: var(--font-weight-base-600);
  position: relative;

  &::after {
    position: absolute;
    content: '';
    inset: 0;
    cursor: none;
  }

  @media (--viewport-md) {
    line-height: 1.1;
    font-size: var(--font-size-28);
  }

  @media (--viewport-xl) {
    font-size: var(--font-size-40);
  }
}
